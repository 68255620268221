import { useCallback, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

import { usePersonaTrack } from '../../tracking'
import { paths } from '../../utils/paths'
import { useTiers } from '../../components/TierProvider/TierProvider'
import type { Locations, PageSources } from '../../types/tracking'
import { useAuth0User } from '../useAuth0User'
import { setLastMarketingProductViewedCookie } from '../../utils/cookies'

export const useNonCommerceGoldStandardEvents = (source?: PageSources) => {
  const { pathname } = useRouter()
  const { trackGoldStandard, trackPageGoldStandard } = usePersonaTrack()
  const { lowestTier } = useTiers()
  const { query } = useRouter()
  const { user, subscription, isLoadingMe, isLoadingSubscription } =
    useAuth0User()
  const isLoggedIn = useMemo(() => !!user, [user])
  const hasActiveSubscription = useMemo(() => !!subscription, [subscription])
  const loaded = useMemo(() => !isLoadingMe, [isLoadingMe])

  // convention: persona-${scope}-${slug}
  const productId = useMemo(() => {
    if (source === 'bio') {
      return `persona-bio-${query.slug}`
    }
    if (source === 'login') {
      return 'persona-login'
    }
    return 'persona-homepage'
  }, [source, query.slug])

  const page = useMemo(() => {
    if (query.slug && source === 'bio') {
      return `${query.slug} bio page${!isLoggedIn ? ' (pre-login)' : ''}`
    }

    if (
      pathname.includes(paths.personaCustomAuth('login')) ||
      pathname.includes(paths.personaCustomAuth('signup'))
    ) {
      return 'step one login: insert email or social'
    }
    if (pathname.includes(paths.personaCheckoutMembership)) {
      return 'step two login: plans (non-free trial)'
    }
    if (pathname.includes(paths.personaCheckoutPayment)) {
      return 'step three login: payment method (non-free trial)'
    }
    if (pathname.includes(paths.personaCheckoutComplete)) {
      return 'order completed'
    }

    return 'persona landing page'
  }, [pathname, isLoggedIn, query.slug, source])

  const sharedProps = useMemo(
    () => ({
      page,
      product_id: productId,
      content_type: ['product'],
      quantity: 1,
      name: lowestTier?.internalData.displayName,
      product_name: 'Persona',
      purchase_currency: lowestTier?.currency.toUpperCase(),
      // All of these properties are the same and it should be the total cost pre-tax
      purchase_amount: lowestTier?.internalData.prices.decimalsMonthly,
      total: lowestTier?.internalData.prices.decimalsUsdMonthly,
      price: lowestTier?.internalData.prices.decimalsUsdMonthly,
      revenue: lowestTier?.internalData.prices.decimalsUsdMonthly,
    }),
    [lowestTier, page, productId],
  )

  const isPageTracked = useRef(false)
  const trackGoldStandardPageViewed = useCallback(() => {
    if (
      !hasActiveSubscription &&
      loaded &&
      !isLoadingSubscription &&
      !isPageTracked.current
    ) {
      trackPageGoldStandard('Page Loaded', {
        name: page,
        product_name: sharedProps.product_name,
        page,
      })
      isPageTracked.current = true
    }
  }, [
    hasActiveSubscription,
    loaded,
    page,
    sharedProps,
    trackPageGoldStandard,
    isLoadingSubscription,
  ])

  const trackCartEmailCaptured = ({ email }: { email: string }) => {
    const { price: _, ...trackProps } = sharedProps
    trackGoldStandard('Cart Email Captured', {
      email,
      location: 'Persona Cart',
      ...trackProps,
    })
  }

  const isProductViewedTracked = useRef(false)
  const trackProductViewed = useCallback(() => {
    if (
      !hasActiveSubscription &&
      loaded &&
      !isLoadingSubscription &&
      !isProductViewedTracked.current
    ) {
      trackGoldStandard('Product Viewed', sharedProps)
      setLastMarketingProductViewedCookie(sharedProps.product_id)
      isProductViewedTracked.current = true
    }
  }, [
    hasActiveSubscription,
    loaded,
    sharedProps,
    trackGoldStandard,
    isLoadingSubscription,
  ])

  const trackPurchaseClick = ({
    cta,
    location,
    instructorSlug,
    pageSource,
  }: {
    cta?: string
    location: Locations
    instructorSlug?: string
    pageSource?: string
  }) => {
    if (!hasActiveSubscription && loaded) {
      const {
        purchase_amount: _,
        total: __,
        revenue: ___,
        purchase_currency: ____,
        ...trackProps
      } = sharedProps
      trackGoldStandard('Purchase Click', {
        ...trackProps,
        cta,
        location,
        ...(instructorSlug && { instructor_slug: instructorSlug }),
        page_source: pageSource,
      })
    }
  }

  return {
    trackCartEmailCaptured,
    trackGoldStandardPageViewed,
    trackProductViewed,
    trackPurchaseClick,
  }
}
