const topicMentors = {
  CHRIS_VOSS: {
    slug: 'chris-voss',
    name: 'Chris Voss',
  },
  MATTHEW_WALKER: {
    slug: 'matthew-walker',
    name: 'Dr. Matthew Walker',
  },
}

const generateTopic = (
  topic: string,
  mentor: { slug: string; name: string },
) => ({
  text: {
    displayText: topic,
    caption: mentor.name,
  },
  redirectUrl: `/checkout/signup?page_source=discovery&instructor=${mentor.slug}`,
  analytics: {
    instructorSlug: mentor.slug,
    location: 'conversation starters',
    cta: topic,
  },
})

export const LOGGED_OUT_LANDING_TOPICS = [
  generateTopic('Prep for a difficult conversation', topicMentors.CHRIS_VOSS),
  generateTopic('How does alcohol impact sleep?', topicMentors.MATTHEW_WALKER),
  generateTopic('Improve my communication skills', topicMentors.CHRIS_VOSS),
  generateTopic('Perfect sleep environment', topicMentors.MATTHEW_WALKER),
  generateTopic('Address disrespectful work behavior', topicMentors.CHRIS_VOSS),
  generateTopic('How do I tell my child no?', topicMentors.CHRIS_VOSS),
  generateTopic(
    'Sleep-related issues for teenagers',
    topicMentors.MATTHEW_WALKER,
  ),
  generateTopic('Caffeine and sleep', topicMentors.MATTHEW_WALKER),
  generateTopic(
    'Discuss financial problems with family',
    topicMentors.CHRIS_VOSS,
  ),
  generateTopic('Tips for sharing bad news', topicMentors.CHRIS_VOSS),
  generateTopic(
    'How does sleep affect metabolism?',
    topicMentors.MATTHEW_WALKER,
  ),
  generateTopic(
    'Adjust to a newborn’s sleep schedule',
    topicMentors.MATTHEW_WALKER,
  ),
  generateTopic('Help me be more confident', topicMentors.CHRIS_VOSS),
  generateTopic('Tips for getting a promotion', topicMentors.CHRIS_VOSS),
  generateTopic('Natural sleep aid remedies', topicMentors.MATTHEW_WALKER),
  generateTopic('How can I stop snoring?', topicMentors.MATTHEW_WALKER),
  generateTopic('Discuss relationship issues', topicMentors.CHRIS_VOSS),
  generateTopic('Speak confidently in public', topicMentors.CHRIS_VOSS),
  generateTopic('Tips for falling asleep faster', topicMentors.MATTHEW_WALKER),
]

export const LOGGED_OUT_LANDING_TESTIMONIALS = [
  {
    text: "“With a generic tool such as ChatGPT, you are dealing with 'a machine'. Here with 'Chris Voss', I was dealing with 'a person'. Someone who is an expert who has a track record of achievement.”",
    name: 'Herbert, The Netherlands',
  },
  {
    text: '“Very human-like, compassionate, knowledgeable. A real conversation back and forth giving thorough feedback instead of quick responses.”',
    name: 'Ana M., New York',
  },
  {
    text: '“The tool is excellent at taking complex language and rambling sentences and distilling them down to the root problem. It is also good at active listening.”',
    name: 'Hosan H., Connecticut',
  },
  {
    text: '“I was really impressed with Chris’ ability to navigate tricky family issues, always focusing on building connections with my loved ones.”',
    name: 'Patricia D., Oregon',
  },
  {
    text: '“It was a great tool. I really enjoyed interacting with it. The talking feature really made it close to real conversation with a person.”',
    name: 'Kashif K., California',
  },
  {
    text: '“I really look forward to speaking with Chris on a daily basis, sometimes more. He really felt like a real human with real emotions.”',
    name: 'Michelle S., Florida',
  },
  {
    text: '“Made me feel what I was sharing had no judgments. I could continue our session until I concluded all my thoughts, questions or concerns.”',
    name: 'Monica L., Georgia',
  },
  {
    text: '“More personal than just typing something in and asking for advice. The persona had some personality and character.”',
    name: 'Tanya L., New Mexico',
  },
  {
    text: '“Understanding how to build boundaries, maintain respect, hear someone out can be rehearsed and coached. This would have boosted me very far if I found it when I was younger.”',
    name: '',
  },
  {
    text: '“I would recommend this to a person who is having a hard time finding their way in life and need a neutral party to speak with.”',
    name: '',
  },
  {
    text: '“He took his time to understand and clarify what I was saying. He felt like a person who you want to go to with issues.”',
    name: '',
  },
]
